import { fetchPageData } from "@/core/sanityAPI/fetchers/pageFetcher";
import SlugPage from "@/pages/[...slug]";

export async function getStaticProps() {
  const data = await fetchPageData("404", false);

  return {
    props: data,
  };
}

export default SlugPage;
